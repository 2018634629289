import React from 'react'
import PropTypes from 'prop-types'

import {
  Divider,
} from '@mui/material'
import {
  useEngagement,
  useSessionUser
} from 'hooks'
import { ResponsiveContent } from 'components'

import CallActionsSection from './components/CallActionsSection'
import CallPrepSection from './components/CallPrepSection'
import ProposalMessage from './components/ProposalMessage'
import CallAvailability from './components/CallAvailability'
import ProposalCanceled from './components/ProposalCanceled'
import ProposalExpired from './components/ProposalExpired'

const EngagementContent = ({ engagementId }) => {
  const { isCustomerUser } = useSessionUser()
  const {
    isProposalCanceled,
    isProposalExpired,
    isCallProposed,
    isCallUpcoming,
    isFeedbackRequired,
  } = useEngagement(engagementId)

  const sections = (isCustomerUser ? [
    // cu sections
    {
      key: 'proposal-canceled',
      Component: ProposalCanceled,
      visible: isProposalCanceled,
    },
    {
      key: 'proposal-expired',
      Component: ProposalExpired,
      visible: isProposalExpired,
    },
    {
      key: 'proposal-message',
      Component: ProposalMessage,
      visible: isCallProposed,
    },
    {
      key: 'call-availability',
      Component: CallAvailability,
      visible: isCallProposed
    },
    {
      key: 'call-actions',
      Component: CallActionsSection,
      visible: isCallUpcoming || isFeedbackRequired
    },
    {
      key: 'call-prep',
      Component: CallPrepSection,
      visible: isCallUpcoming
    },
  ] : [
    {
      key: 'proposal-canceled',
      Component: ProposalCanceled,
      visible: isProposalCanceled,
    },
    {
      key: 'call-actions',
      Component: CallActionsSection,
      visible: isCallUpcoming || isFeedbackRequired
    },
    {
      key: 'call-prep',
      Component: CallPrepSection,
      visible: isCallUpcoming
    },
  ]).filter(({ visible }) => visible)

  const activityLogExpandedByDefault = sections.length === 1

  return (
    <ResponsiveContent>
      {sections.map(({ key, Component }, index) => (
        <React.Fragment key={key}>
          <Component
            key={key}
            engagementId={engagementId}
            // if activity log is the only section rendered, expand it by default
            {...(key === 'activity-log' ? { expandedByDefault: activityLogExpandedByDefault } : {})}
          />
          {index < sections.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </ResponsiveContent>
  )
}

EngagementContent.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default EngagementContent
