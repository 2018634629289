import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Tooltip,
  Typography
} from '@mui/material'
import { Building, Mail, Information } from 'icons'
import {
  EngagementEndChip,
  EngagementNotificationChips,
  EngagementStateChip,
  IconTextCombo
} from 'components'
import {
  buildJsonApiOne,
  copyToClipboard,
  formatDate,
  isEngagementCallToday,
  getEmailRelayLink
} from 'utils-em'

const EngagementDetailsHeader = ({ engagementId }) => {
  const user = useSelector(({ session }) => session)
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const isAdvisor = user.id === engagement.advisorId
  const isDeclinedOrExpired = engagement.state === 'rejected' || engagement.state === 'expired'
  const isProposedState = engagement.state === 'proposed'
  const [isCopied, setIsCopied] = React.useState(false)

  let title
  if (isAdvisor) {
    const pretext = isProposedState ? 'Call proposal from' : 'Engagement with'
    title = `${pretext} ${engagement.customerUser && engagement.customerUser.firstName} from
    ${engagement.customerUser && engagement.customerUser.customer.name}`
  } else {
    const { advisor } = engagement
    title = `Engagement with ${advisor.firstName}`
    if (isProposedState) title = `Call proposal with ${advisor.firstName}`
    if (isDeclinedOrExpired) title = `Proposed call with ${advisor.firstName}`
  }

  const getIcon = (icon, text, isLink = false) => (
    <IconTextCombo
      typography="body1"
      typographyColor="neutral.black"
      iconSize="medium"
      iconColor="neutral.darkGrey"
      icon={icon}
      text={text}
      isLink={isLink}
    />
  )

  const renderInfo = () => {
    if (isAdvisor && engagement.dateAdvisorIndicatedAvailable) {
      const formattedDate = formatDate(engagement.dateAdvisorIndicatedAvailable, { includeYear: true })
      return (
        <IconTextCombo
          typography="body1"
          iconSize="medium"
          iconColor="neutral.darkGrey"
          sx={{ mb: 3, mt: 2 }}
          key={`indicated-available-${user.id}`}
          icon={<Information />}
          text={`You notified the seller that you're available on ${formattedDate}.`}
        />
      )
    }
    return null
  }

  const relayLink = getEmailRelayLink(engagement)
  const renderSubHeaderIcons = () => (
    <Box sx={{ display: 'flex', gap: 3, mt: '20px' }}>
      {getIcon(<Building />, engagement.organization.name)}
      <EngagementEndChip engagementId={engagementId} />
      { engagement.state === 'active' && (
        <Tooltip title={isCopied ? 'Copied to clipboard!' : ''}>
          <Box onClick={() => {
            copyToClipboard(relayLink)
            setIsCopied(true)
          }}
          >
            {getIcon(<Mail />, relayLink, true)}
          </Box>
        </Tooltip>
      )}
    </Box>
  )

  return (
    <Box sx={{ ml: 6 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', gap: 2 }}>
        <Typography color="neutral.black" variant="h1">
          {title}
        </Typography>
        <EngagementStateChip engagementState={engagement.state} isCallToday={isEngagementCallToday(engagement)} />
        <EngagementNotificationChips engagementId={engagementId} />
      </Box>
      {renderSubHeaderIcons()}
      {renderInfo()}
    </Box>
  )
}

EngagementDetailsHeader.propTypes = {
  engagementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default EngagementDetailsHeader
