import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography
} from '@mui/material'
import {
  useEngagement,
} from 'hooks'

import UpcomingCallBox from './components/UpcomingCallBox'

const CallActionsSection = ({ engagementId }) => {
  const { isCallUpcoming, isFeedbackRequired, isCallInThirty } = useEngagement(engagementId)
  if (isCallUpcoming) {
    return (
      <>
        <Typography variant="h3">
          {isCallInThirty ? 'Your call is happening soon.' : 'Your call has been scheduled.'}
        </Typography>
        <UpcomingCallBox engagementId={engagementId} />
      </>

    )
  }
  if (isFeedbackRequired) return <>How did it go?: TODO in SC-31991</>
  return null
}

CallActionsSection.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default CallActionsSection
