import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Link,
  Typography
} from '@mui/material'
import {
  useEngagement
} from 'hooks'
import {
  Lightbulb,
  Mail,
  QuestionMark,
  Phone,
  Share,
  UsersOutline
} from 'icons'
import {
  copyToClipboardWithAlert
} from 'utils-em'

const CallPrepSection = ({ engagementId }) => {
  const { isCustomerUser, relayAddress, isCallInThirty } = useEngagement(engagementId)

  const RelayLink = () => (
    <Link onClick={() => copyToClipboardWithAlert(relayAddress)} sx={{ color: 'neutral.black' }}>
      {relayAddress}
    </Link>
  )

  const bullets = isCustomerUser ? (
    [ // cu bullets
      {
        Icon: UsersOutline,
        text: `
          If you have colleagues you'd like to invite to join the call, add them using 
          the button on the side panel. They will receive a Zoom link and be able to 
          access the recording and transcript once your call is complete.
        `
      },
      {
        Icon: Mail,
        text: (
          <>
            You and your advisor can exchange emails using a custom email address that goes
            directly to your inbox:&nbsp;
            <RelayLink />
            .
          </>
        )
      },
      {
        Icon: Phone,
        hidden: isCallInThirty,
        text: `
          A link to the Zoom call will be available 30 minutes before your scheduled call time.
        `
      },
      {
        Icon: Lightbulb,
        text: `
          Remember - you are in charge of leading the call! Ensure you are well-prepared 
          with the questions you want to ask and have your goals clearly defined.
        `
      },
      {
        Icon: QuestionMark,
        text: (
          <Box>
            <Box>
              If you need help building your call plan, you can download Emissary’s helpful
              guide which contains how-to’s and question starters.
            </Box>
            <Button
              startIcon={<Share />}
              target="_blank"
              href="https://emissary.io/wp-content/uploads/2024/08/Emissary-Call-Prep-Guide.pdf"
              sx={{ m: 0, mt: 2 }}
            >
              Call Prep Guide
            </Button>
          </Box>
        )

      },

    ]
  ) : (
    [ // au bullets
      {
        Icon: Mail,
        text: (
          <>
            You and your client can exchange emails using a custom email address that goes
            directly to your inbox:&nbsp;
            <RelayLink />
            .
          </>
        )
      },
      {
        Icon: Phone,
        hidden: isCallInThirty,
        text: `
          A link to the Zoom call will be available 30 minutes before your scheduled call time.
        `
      }
    ]
  )

  // eslint-disable-next-line react/prop-types
  const Bullet = ({ Icon, text }) => (
    <Box key={text} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
      <Icon sx={{ color: 'brand.darkTeal' }} />
      <Typography>{text}</Typography>
    </Box>
  )

  return (
    <>
      <Typography variant="h3">Prepare for your call:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {bullets.filter(({ hidden }) => !hidden).map((bullet) => (
          <Bullet {...bullet} />
        ))}
      </Box>
    </>
  )
}

CallPrepSection.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default CallPrepSection
